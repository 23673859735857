export const changeSeoValues = (configData) => {
  // FavIcon
  if (configData?.imageUrls?.favIcon) {
    document.getElementById('favicon')?.setAttribute('href', configData.imageUrls.favIcon)
  }
  // Title
  if(configData?.texts?.seoTitle){
    document.title = configData.texts.seoTitle
  }
  // Description
  document.querySelector('meta[name="description"]')?.setAttribute(
    'content',
    configData?.texts?.seoDescription
      ? configData.texts.seoDescription
      : 'Learning Management System'
  )
}

export const addFontFaces = (configData) => {
  configData?.fontFaces?.forEach(fontFace => {
    let newStyle = document.createElement('style');
    newStyle.appendChild(document.createTextNode(fontFace));
    document.head.appendChild(newStyle);
  });
}

export const applyColors = (root, configData) => {
  root?.style.setProperty(
    '--background-color',
    configData?.colors?.backgroundColor ? configData.colors.backgroundColor : '#FBFCFD'
  )
  root?.style.setProperty(
    '--primary-text-color',
    configData?.colors?.primaryTextColor ? configData.colors.primaryTextColor : '#6787A9'
  )
  root?.style.setProperty(
    '--landing-text-color',
    configData?.colors?.landingTextColor ? configData.colors.landingTextColor : '#023770'
  )
  root?.style.setProperty(
    '--all-courses-section-title-color',
    configData?.colors?.allCoursesSectionTitleColor
      ? configData.colors.allCoursesSectionTitleColor
      : '#FBFCFD'
  )
  root?.style.setProperty(
    '--all-courses-section-title-background-color',
    configData?.colors?.allCoursesSectionTitleBackgroundColor
      ? configData.colors.allCoursesSectionTitleBackgroundColor
      : '#023770'
  )
  root?.style.setProperty(
    '--all-courses-grid-item-background-color',
    configData?.colors?.allCoursesGridItemBackgroundColor
      ? configData.colors.allCoursesGridItemBackgroundColor
      : '#FFFFFF'
  )
  root?.style.setProperty(
    '--all-courses-item-time-length-color',
    configData?.colors?.allCoursesItemTimeLengthColor ? configData.colors.allCoursesItemTimeLengthColor : '#6787A9'
  )
  root?.style.setProperty(
    '--all-courses-item-subtitle-color',
    configData?.colors?.allCoursesItemSubtitleColor
      ? configData.colors.allCoursesItemSubtitleColor
      : '#6787A9'
  )
  root?.style.setProperty(
    '--all-courses-item-title-color',
    configData?.colors?.allCoursesItemTitleColor
      ? configData.colors.allCoursesItemTitleColor
      : '#023770'
  )
  root?.style.setProperty(
    '--all-courses-item-button-background-color',
    configData?.colors?.allCoursesItemButtonBackgroundColor
      ? configData.colors.allCoursesItemButtonBackgroundColor
      : '#E5EBF0'
  )
  root?.style.setProperty(
    '--all-courses-item-button-primary-background-color',
    configData?.colors?.allCoursesItemButtonPrimaryBackgroundColor
      ? configData.colors.allCoursesItemButtonPrimaryBackgroundColor
      : '#023770'
  )
  root?.style.setProperty(
    '--all-courses-item-button-color',
    configData?.colors?.allCoursesItemButtonColor
      ? configData.colors.allCoursesItemButtonColor
      : '#6787A9'
  )
  root?.style.setProperty(
    '--all-courses-item-button-primary-color',
    configData?.colors?.allCoursesItemButtonPrimaryColor
      ? configData.colors.allCoursesItemButtonPrimaryColor
      : '#FBFCFD'
  )
  root?.style.setProperty(
    '--footer-background-color',
    configData?.colors?.footerBackgroundColor ? configData.colors.footerBackgroundColor : '#E5EBF0'
  )
  root?.style.setProperty(
    '--footer-color',
    configData?.colors?.footerColor ? configData.colors.footerColor : '#6787A9'
  )
  root?.style.setProperty(
    '--scrollTop-background-color',
    configData?.colors?.scrollTopBackgroundColor
      ? configData.colors.scrollTopBackgroundColor
      : '#6787A9'
  )
  root?.style.setProperty(
    '--scrollTop-color',
    configData?.colors?.scrollTopColor ? configData.colors.scrollTopColor : '#FFFFFF'
  )
  root?.style.setProperty(
    '--selected-menu-item-border-color',
    configData?.colors?.selectedMenuItemBorderColor
      ? configData.colors.selectedMenuItemBorderColor
      : '#F39200'
  )
  root?.style.setProperty(
    '--selected-navbar-item-border-color',
    configData?.colors?.selectedNavbarItemBorderColor
      ? configData.colors.selectedNavbarItemBorderColor
      : '#F4B249'
  )
  root?.style.setProperty(
    '--header-menu-item-color',
    configData?.colors?.headerMenuItemColor ? configData.colors.headerMenuItemColor : '#6787A9'
  )
  root?.style.setProperty(
    '--header-selected-menu-item-color',
    configData?.colors?.headerSelectedMenuItemColor
      ? configData.colors.headerSelectedMenuItemColor
      : '#023770'
  )
  root?.style.setProperty(
    '--header-dropdown-bg-color',
    configData?.colors?.headerDropdownBgColor ? configData.colors.headerDropdownBgColor : '#6787A9'
  )
  root?.style.setProperty(
    '--header-dropdown-selected-bg-color',
    configData?.colors?.headerDropdownSelectedBgColor
      ? configData.colors.headerDropdownSelectedBgColor
      : '#E5EBF0'
  )
  root?.style.setProperty(
    '--header-dropdown-item-subtext-color',
    configData?.colors?.headerDropdownItemSubtextColor
      ? configData.colors.headerDropdownItemSubtextColor
      : '#C0C0C0'
  )
  root?.style.setProperty(
    '--myCourse-subjects-background-color',
    configData?.colors?.myCourseSubjectsBackgroundColor
      ? configData.colors.myCourseSubjectsBackgroundColor
      : '#6787A9'
  )
  root?.style.setProperty(
    '--myCourse-subjects-items-background-color',
    configData?.colors?.myCourseSubjectsItemsBackgroundColor
      ? configData.colors.myCourseSubjectsItemsBackgroundColor
      : '#526C87'
  )
  root?.style.setProperty(
    '--myCourse-subjects-lang-toggle-background-color',
    configData?.colors?.myCourseSubjectsLangToggleBackgroundColor
      ? configData.colors.myCourseSubjectsLangToggleBackgroundColor
      : '#F39200'
  )
  root?.style.setProperty(
    '--myCourse-subjects-lang-toggle-text-color',
    configData?.colors?.myCourseSubjectsLangToggleTextColor
      ? configData.colors.myCourseSubjectsLangToggleTextColor
      : '#FBFCFD'
  )
  root?.style.setProperty(
    '--myCourse-subjects-color',
    configData?.colors?.myCourseSubjectsColor ? configData.colors.myCourseSubjectsColor : '#FBFCFD'
  )
  root?.style.setProperty(
    '--myCourse-subjects-items-color',
    configData?.colors?.myCourseSubjectsItemsColor
      ? configData.colors.myCourseSubjectsItemsColor
      : '#FBFCFD'
  )
  root?.style.setProperty(
    '--myCourse-quizResult-bg-color',
    configData?.colors?.myCourseQuizResultBgColor
      ? configData.colors.myCourseQuizResultBgColor
      : '#F0F1F1'
  )
  root?.style.setProperty(
    '--myCourse-quizResult-success-color',
    configData?.colors?.myCourseQuizResultSuccessColor
      ? configData.colors.myCourseQuizResultSuccessColor
      : '#03BB86'
  )
  root?.style.setProperty(
    '--myCourse-quizResult-failure-color',
    configData?.colors?.myCourseQuizResultFailureColor
      ? configData.colors.myCourseQuizResultFailureColor
      : '#DE3A5A'
  )
  root?.style.setProperty(
    '--myCourse-quizResult-primary-color',
    configData?.colors?.myCourseQuizResultPrimaryColor
      ? configData.colors.myCourseQuizResultPrimaryColor
      : '#0E154C'
  )
  root?.style.setProperty(
    '--myCourse-quizResult-secondary-color',
    configData?.colors?.myCourseQuizResultSecondaryColor
      ? configData.colors.myCourseQuizResultSecondaryColor
      : '#6E7072'
  )
  root?.style.setProperty(
    '--myCourse-quizResult-btn-text-color',
    configData?.colors?.myCourseQuizResultBtnTextColor
      ? configData.colors.myCourseQuizResultBtnTextColor
      : '#F0F1F1'
  )
  root?.style.setProperty(
    '--myCourse-details-title-color',
    configData?.colors?.myCourseDetailsTitleColor
      ? configData.colors.myCourseDetailsTitleColor
      : '#023770'
  )
  root?.style.setProperty(
    '--primary-button-background-color',
    configData?.colors?.primaryButtonBackgroundColor
      ? configData.colors.primaryButtonBackgroundColor
      : '#F39200'
  )
  root?.style.setProperty(
    '--navbar-myCourses-background-color',
    configData?.colors?.navbarMyCoursesBackgroundColor
      ? configData.colors.navbarMyCoursesBackgroundColor
      : '#023770'
  )
  root?.style.setProperty(
    '--navbar-myCourses-color',
    configData?.colors?.navbarMyCoursesColor
      ? configData.colors.navbarMyCoursesColor
      : 'rgba(251,252,253,0.65)'
  )
  root?.style.setProperty(
    '--navbar-myCourses-selected-color',
    configData?.colors?.navbarMyCoursesSelectedColor
      ? configData.colors.navbarMyCoursesSelectedColor
      : 'rgb(251,252,253)'
  )
  root?.style.setProperty(
    '--faq-background-color',
    configData?.colors?.faqBackgroundColor ? configData.colors.faqBackgroundColor : '#FFFFFF'
  )
  root?.style.setProperty(
    '--faq-button-color',
    configData?.colors?.faqButtonColor ? configData.colors.faqButtonColor : '#F39200'
  )
  root?.style.setProperty(
    '--faq-text-color',
    configData?.colors?.faqTextColor ? configData.colors.faqTextColor : '#6787A9'
  )
  root?.style.setProperty(
    '--certificate-button-background-color',
    configData?.colors?.certificateButtonBackgroundColor
      ? configData.colors.certificateButtonBackgroundColor
      : '#FFFFFF'
  )
  root?.style.setProperty(
    '--certificate-button-color',
    configData?.colors?.certificateButtonColor
      ? configData.colors.certificateButtonColor
      : '#023770'
  )
  root?.style.setProperty(
    '--certificate-download-icon-color',
    configData?.colors?.certificateDownloadIconColor
      ? configData.colors.certificateDownloadIconColor
      : '#F39200'
  )
  root?.style.setProperty(
    '--no-course-text-color',
    configData?.colors?.noCourseTextColor ? configData.colors.noCourseTextColor : '#6787A9'
  )
  root?.style.setProperty(
    '--searchBox-inputs',
    configData?.colors?.searchBoxInputs ? configData.colors.searchBoxInputs : '#FFFFFF'
  )
  root?.style.setProperty(
    '--Icon_feather-play',
    configData?.colors?.iconFeatherPlay ? configData.colors.iconFeatherPlay : '#F39200'
  )
  root?.style.setProperty(
    '--scrollbar-color',
    configData?.colors?.scrollbarColor ? configData.colors.scrollbarColor : '#F39200'
  )
  root?.style.setProperty(
    '--explore-button-bg-color',
    configData?.colors?.exploreButtonBgColor ? configData.colors.exploreButtonBgColor : '#023770'
  )
  root?.style.setProperty(
    '--explore-button-text-color',
    configData?.colors?.exploreButtonTextColor
      ? configData.colors.exploreButtonTextColor
      : '#FBFCFD'
  )
  root?.style.setProperty(
    '--progress-empty-color',
    configData?.colors?.progressEmptyColor ? configData.colors.progressEmptyColor : '#E5EBF0'
  )
  root?.style.setProperty(
    '--progress-fill-color',
    configData?.colors?.progressFillColor ? configData.colors.progressFillColor : '#6787A9'
  )
  root?.style.setProperty(
    '--input-border-color',
    configData?.colors?.inputBorderColor ? configData.colors.inputBorderColor : '#E5EBF0'
  )
  root?.style.setProperty(
    '--input-invalid-border-color',
    configData?.colors?.inputInvalidBorderColor
      ? configData.colors.inputInvalidBorderColor
      : '#F39200'
  )
  root?.style.setProperty(
    '--login-welcome-title-color',
    configData?.colors?.loginWelcomeTitleColor ? configData.colors.loginWelcomeTitleColor : '#023770'
  )
  root?.style.setProperty(
    '--login-box-color',
    configData?.colors?.loginBoxColor ? configData.colors.loginBoxColor : '#FFFFFF'
  )
  root?.style.setProperty(
    '--login-box-shadow-color',
    configData?.colors?.loginBoxShadowColor ? configData.colors.loginBoxShadowColor : '#0237701D'
  )
  root?.style.setProperty(
    '--form-title-color',
    configData?.colors?.formTitleColor ? configData.colors.formTitleColor : '#023770'
  )
  root?.style.setProperty(
    '--form-error-color',
    configData?.colors?.formErrorColor ? configData.colors.formErrorColor : '#F39200'
  )
  root?.style.setProperty(
    '--form-submit-button-bg-color',
    configData?.colors?.formSubmitButtonBgColor
      ? configData.colors.formSubmitButtonBgColor
      : '#023770'
  )
  root?.style.setProperty(
    '--form-submit-button-text-color',
    configData?.colors?.formSubmitButtonTextColor
      ? configData.colors.formSubmitButtonTextColor
      : '#FBFCFD'
  )
  root?.style.setProperty(
    '--form-input-bg-color',
    configData?.colors?.formInputBgColor ? configData.colors.formInputBgColor : '#FBFCFD'
  )
  root?.style.setProperty(
    '--errorpage-icon-color',
    configData?.colors?.errorPageIconColor ? configData.colors.errorPageIconColor : '#6787A9'
  )
  root?.style.setProperty(
    '--errorpage-title-color',
    configData?.colors?.errorPageTitleColor ? configData.colors.errorPageTitleColor : '#023770'
  )
  root?.style.setProperty(
    '--errorpage-text-color',
    configData?.colors?.errorPageTextColor ? configData.colors.errorPageTextColor : '#6787A9'
  )
  root?.style.setProperty(
    '--errorpage-btn-bg-color',
    configData?.colors?.errorPageBtnBgColor ? configData.colors.errorPageBtnBgColor : '#023770'
  )
  root?.style.setProperty(
    '--errorpage-btn-text-color',
    configData?.colors?.errorPageBtnTextColor ? configData.colors.errorPageBtnTextColor : '#FBFCFD'
  )
}